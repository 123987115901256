<template>
    <div style="display: block">
        <div
            v-for="(item, index) in ArrPreviewImg"
            :key="index"
            :style="`width: ${width}; height: ${height};  ${
                HinhDang == 'square'
                    ? 'border-radius: 8px;'
                    : HinhDang == 'circle'
                    ? 'border-radius: 50%;'
                    : ''
            }`"
            class="item-anh"
        >
            <div
                class="row align-center"
                style="width: 100%; height: 100%; overflow: hidden"
            >
                <img
                    :src="item"
                    alt=""
                    style="`width: auto; height: 100%`"
                    :style="
                        HinhDang == 'square'
                            ? 'border-radius: 8px;'
                            : HinhDang == 'circle'
                            ? 'border-radius: 50%;'
                            : ''
                    "
                    @click="XemAnh(item)"
                />
            </div>
            <i
                class="mdi mdi-close btn-delete"
                @click="XoaAnh(index)"
                v-if="Edit"
            ></i>
        </div>
        <div
            class="chon-anh"
            :style="`width: ${width}; height: ${height};  ${
                HinhDang == 'square'
                    ? 'border-radius: 8px;'
                    : HinhDang == 'circle'
                    ? 'border-radius: 50%;'
                    : ''
            }`"
            v-if="Edit && ArrPreviewImg.length < SoLuongAnh"
        >
            <input
                type="file"
                accept="image/*"
                class="input-file"
                id="input-file"
                multiple
                @change="ThemAnh"
            />
            <div class="row full-height justify-center align-center">
                <i class="mdi mdi-camera-plus"></i>
            </div>
        </div>
    </div>
    <ViewImageVue v-model:statePopup="statePopup" :link="link" />
</template>

<script>
import ViewImageVue from "../../../components/_Common/ViewImage.vue";
export default {
    components: {
        ViewImageVue,
    },
    props: {
        ArrPreviewImg: { type: Array, default: () => [] },
        ArrImgPush: { type: Array, default: () => [] },
        width: { type: String, default: "80px" },
        height: { type: String, default: "80px" },
        Edit: { type: Boolean, default: true },
        SoLuongAnh: { type: Number, default: 3 },
        HinhDang: { type: String, default: "square" },
    },
    data() {
        return {
            link: "",
            statePopup: false,
        };
    },
    methods: {
        XoaAnh(index) {
            let ArrPreviewImg = [...this.ArrPreviewImg];
            let ArrImgPush = [...this.ArrImgPush];
            ArrPreviewImg.splice(index, 1);
            ArrImgPush.splice(index, 1);
            this.$emit("update:ArrPreviewImg", ArrPreviewImg);
            this.$emit("update:ArrImgPush", ArrImgPush);
        },
        XemAnh(item) {
            this.link = item;
            this.statePopup = true;
        },
        ThemAnh(ev) {
            let toast = {
                type: "error",
                visible: true,
            };
            var self = this;
            var files = Array.from(ev.target.files || []);
            var fileInput = document.getElementById("input-file");
            var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            var check = false;
            files.forEach((e) => {
                if (!allowedExtensions.exec(e.name)) {
                    return (check = true);
                }
            });
            if (check == true) {
                toast.message = "Chỉ chọn tệp có định dạng: .jpeg/.jpg/.png";
                this.emitter.emit("onToast", toast);
                fileInput.value = null;
                return false;
            } else {
                let ArrPreviewImg = [...this.ArrPreviewImg];
                let ArrImgPush = [...this.ArrImgPush];
                if (ArrPreviewImg.length + files.length < this.SoLuongAnh + 1) {
                    files.forEach(async function (file) {
                        // Kiểm tra nhỏ hơn 4MB
                        if (file.size < 6 * 1024 * 1024) {
                            var reader = new FileReader();
                            reader.onload = await function (e) {
                                self.$startLoading;
                                if (FileReader.DONE == this.readyState) {
                                    var content = this.result;
                                    var blob = new Blob([content], {
                                        type: file.type,
                                    });

                                    var image = new Image();
                                    image.src = URL.createObjectURL(blob);
                                    image.onload = function (imageEvent) {
                                        var canvas =
                                                document.createElement(
                                                    "canvas"
                                                ),
                                            max_size = 720, // TODO : pull max size from a site config
                                            width = image.width,
                                            height = image.height;
                                        if (width > height) {
                                            if (width > max_size) {
                                                height *= max_size / width;
                                                width = max_size;
                                            }
                                        } else {
                                            if (height > max_size) {
                                                width *= max_size / height;
                                                height = max_size;
                                            }
                                        }
                                        canvas.width = width;
                                        canvas.height = height;
                                        canvas
                                            .getContext("2d")
                                            .drawImage(
                                                image,
                                                0,
                                                0,
                                                width,
                                                height
                                            );
                                        var dataUrl =
                                            canvas.toDataURL("image/jpeg");
                                        var resizedImage =
                                            self.dataURLToBlob(dataUrl);

                                        ArrPreviewImg.push(
                                            URL.createObjectURL(blob)
                                        );
                                        ArrImgPush.push(resizedImage);
                                        
                                        setTimeout(() => {
                                            self.$emit(
                                                "update:ArrPreviewImg",
                                                ArrPreviewImg
                                            );
                                            self.$emit(
                                                "update:ArrImgPush",
                                                ArrImgPush
                                            );
                                        }, 300);
                                    };
                                }
                                self.$stopLoading;
                            };
                            var a = reader.readAsArrayBuffer(file);
                        } else {
                            alert("Vui lòng chỉ chọn ảnh nhỏ hơn 6MB!");
                        }
                    });
                } else {
                    toast.message = `Vui lòng chọn tối đa ${this.SoLuongAnh} ảnh`;
                    this.emitter.emit("onToast", toast);
                    fileInput.value = "";
                }
            }
            ev.target.value = null;
        },
        dataURLToBlob(dataURL) {
            var BASE64_MARKER = ";base64,";
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
                var parts = dataURL.split(",");
                var contentType = parts[0].split(":")[1];
                var raw = parts[1];

                return new Blob([raw], { type: contentType });
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(":")[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], { type: contentType });
        },
    },
};
</script>

<style scoped>
.item-anh {
    margin-right: 8px;
    border: 1px dashed #dadce0;
    position: relative;
    float: left;
    margin-bottom: 8px;
}
.item-anh img {
    width: 100%;
    height: auto;
}
.btn-delete {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    font-size: 16px;
    border-radius: 50px;
    text-align: center;
    color: #fff;
    background: #a1a1a1;
}
.chon-anh {
    border: 1px dashed #000;
    opacity: 0.2;
    position: relative;
    float: left;
}
.chon-anh i {
    font-size: 32px;
}
.chon-anh .input-file {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    z-index: 1;
}
</style>