<template>
    <DxPopup
        :visible="statePopup"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="false"
        :fullScreen="true"
        :wrapperAttr="{
            class: 'popup-no-padding popup-view-img',
        }"
    >
        <DxButton
            type="default"
            icon="mdi mdi-close"
            class="btn-hidden-img"
            @click="onHidden"
        />
        <!-- :id="id" -->
        <div
            class="row full-height align-center justify-center"
            style="position: relative; max-height: calc(100vh - 96px); overflow: hidden"
        >
            <img
                v-show="LoadDone"
                :src="link"
                alt=""
                id="img-view"
                style="width: 100%; height: auto"
            />
        </div>
    </DxPopup>
</template>

<script>
import { DxPopup, DxButton } from "devextreme-vue";
import Panzoom from "@panzoom/panzoom";
import { onIonViewWillEnter } from "@ionic/vue";
export default {
    components: {
        DxPopup,
        DxButton,
    },
    props: {
        link: { type: String, default: "" },
        statePopup: { type: Boolean, default: false },
    },
    data() {
        return {
            randomText: null,
            LoadDone: false,
        };
    },
    methods: {
        onHidden() {
            this.$emit("update:statePopup", false);
        },
    },
    watch: {
        statePopup() {
            this.LoadDone = false;
            if (this.statePopup == true) {
                setTimeout(() => {
                    let elem = document.getElementById("img-view");
                    const panzoom = Panzoom(elem, { canvas: true });
                    this.LoadDone = true;
                }, 150);
            }
        },
    },
    mounted() {
        onIonViewWillEnter(() => {
            this.randomText = Math.random();
        });
    },
};
</script>

<style>
.popup-view-img .dx-popup-content {
    background: #000;
    padding: 48px 0;
}
</style>
<style scoped>
.btn-hidden-img {
    position: fixed;
    top: 4px;
    right: 4px;
    margin: 0;
}
</style>
